import * as React from "react";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from "gatsby-plugin-react-intl";
import { Helmet } from 'react-helmet';
import { GatsbyImage } from "gatsby-plugin-image";
import * as _ from "lodash";

const News = (): JSX.Element => {
  const m = useIntl().messages;
  const newsData = useStaticQuery(graphql`{
    mdStats: allMarkdownRemark(
      filter: {frontmatter: {slug: {regex: "/^\\/news.*/"}}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date
            locale
            cover {
              childImageSharp {
                gatsbyImageData(
                  width: 266
                  height: 230
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                  layout: FIXED
                )
              }
            }
          }
        }
      }
    }
  }
  `);

  const Cards = (props: any): JSX.Element => {
    return <>{
      _.orderBy(props.data.edges, ['node.frontmatter.date', ['node.frontmatter.title']], ['desc', 'asc'])
        .map(edge => {
          const frontmatter = edge.node.frontmatter;
          const cover = frontmatter.cover;
          const locale = edge.node.frontmatter.locale;
          const classDirection = (locale === 'ps' || locale === 'fa') ? 'text-rtl' : 'text-ltr';

          return (
            <a href={frontmatter.slug} className={"ui link card overlay-caption " + classDirection} key={edge.node.id}>
              <div className="image">
                {cover && <GatsbyImage
                  image={cover?.childImageSharp?.gatsbyImageData}
                  alt={frontmatter.title}
                  className="ui centered big image"/>}
              </div>

              <div className="content">
                <div className="header">{frontmatter.title}</div>
                <div className="meta">
                  <div className="date text-ltr">{frontmatter.date}</div>
                </div>
              </div>
            </a>
          );
        })
    }</>;
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{m.news}</title>
      </Helmet>
      <div className="ui pusher four cards" style={{alignItems: 'baseline'}}>
        <Cards data={newsData.mdStats}/>
      </div>
    </Layout>
  );
};

export default News;